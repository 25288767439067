var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Sales Report "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":_vm.sales.loading},on:{"click":_vm.getSales}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"footer-props":{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': _vm.sales.loading
        },"items":_vm.sales.list,"options":_vm.sales.options,"page":_vm.sales.meta.page,"server-items-length":_vm.sales.meta.total || 0,"loading":_vm.sales.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"update:options":function($event){return _vm.$set(_vm.sales, "options", $event)},"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.logs",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiInformation))])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s((item.logs || []).join('<br>'))}})])]}},{key:"item.payout",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.token)+" "+_vm._s(item.blockchain)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }